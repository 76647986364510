import React, { createContext, useState, useContext, useEffect, useCallback, useMemo, useRef } from 'react';
import { sendGetRequest } from '../../Common/sendGetRequest';
import { useLocation } from 'react-router-dom';
import { filterFeedbackData } from './Evaluation/evaluationFunctions';
import { useLocalStorage } from '../../Common/useLocalStorage';

const FeedbackContext = createContext();

export const useFeedback = () => useContext(FeedbackContext);

export const FeedbackProvider = ({ children }) => {
  const [rawFeedbackData, setRawFeedbackData] = useState([]);
  const [filterState, setFilterState] = useLocalStorage('feedbackContextState', {
    target: 'Kursteilnehmer',
    filters: {},
    dateRange: { start: null, end: null }
  });
  const [dataFetched, setDataFetched] = useState(false);
  const previousFilteredData = useRef(null);
  const location = useLocation();

  const feedbackRoutes = [
    '/dashboard',
    '/evaluation/questions',
    '/evaluation/surveys',
    '/comments',
    '/heatmap'
  ];

  const applyFilters = useCallback((data, filters) => {
    if (!data?.length) return [];
    
    // First filter by target
    let filtered = data.filter(item => item.Target === filters.target);

    // Apply active filters
    Object.entries(filters.filters).forEach(([key, value]) => {
      if(key==="Question")
      {
        key="QuestionID"
        if (value && value.length) {
          let questionFiltered = filtered.filter(item => value.includes(item[key]));
          let feedbackIDs = questionFiltered.map(item => item.CoursefeedbackID);
          filtered = filtered.filter(item => feedbackIDs.includes(item.CoursefeedbackID));
        }
      }
      else if (value && value.length) {
        filtered = filtered.filter(item => value.includes(item[key]));
      }
    });

    // Apply date range if exists
    if (filters.dateRange.start && filters.dateRange.end) {
      filtered = filterFeedbackData(filtered, filters.dateRange.start, filters.dateRange.end);
    }
    console.log("Filtered", filtered);
    return filtered;
  }, []);

  const feedbackData = useMemo(() => {
    const newFilteredData = applyFilters(rawFeedbackData, filterState);
    
    // Compare with previous filtered data to prevent unnecessary updates
    if (JSON.stringify(newFilteredData) === JSON.stringify(previousFilteredData.current)) {
      return previousFilteredData.current;
    }
    
    previousFilteredData.current = newFilteredData;
    return newFilteredData;
  }, [rawFeedbackData, filterState, applyFilters]);

  const loadFeedbackData = useCallback(async () => {
    try {
      const response = await sendGetRequest("feedback");
      setRawFeedbackData(prevData => {
        if (JSON.stringify(prevData) !== JSON.stringify(response)) {
          return response;
        }
        return prevData;
      });
      setDataFetched(true);
    } catch (error) {
      console.error("Error fetching feedback:", error);
      setDataFetched(false);
    }
  }, []);

  useEffect(() => {
    const shouldFetchData = feedbackRoutes.some(route => 
      location.pathname.startsWith(route)
    );

    if (shouldFetchData) {
      loadFeedbackData();
      const interval = setInterval(loadFeedbackData, 10000);
      return () => clearInterval(interval);
    }
  }, [location.pathname, loadFeedbackData]);

  const updateFilters = useCallback((newFilters) => {
    setFilterState(prev => ({
      ...prev,
      ...newFilters
    }));
  }, []);

  const updateFeedbackData = (newData) => {
    setRawFeedbackData(newData);
  };

  const contextValue = useMemo(() => ({
    feedbackData,
    allFeedbackData: rawFeedbackData,
    dataFetched,
    updateFilters,
    filterState,
    updateFeedbackData
  }), [feedbackData, rawFeedbackData, dataFetched, updateFilters, filterState]);

  return (
    <FeedbackContext.Provider value={contextValue}>
      {children}
    </FeedbackContext.Provider>
  );
};
