
import { DonutDiagram } from "../../../Diagrams/DonutDiagram";
import ReactApexChart from "react-apexcharts";


export default function TrainerDiagram(props) {

  const trainerDonutDiagram = JSON.parse(JSON.stringify(DonutDiagram));
  trainerDonutDiagram.options.labels = props.labelsForTrainerPieChart;
  trainerDonutDiagram.options.title.text = props.header;
  trainerDonutDiagram.options.plotOptions.pie.donut.labels.total.label =
    "Total";
  trainerDonutDiagram.options.colors = [];

  if(props.seriesForTrainerPieChart.length>4)
  {
    trainerDonutDiagram.options.legend.height=40;
  }

  return(
    <ReactApexChart
    options={trainerDonutDiagram.options}
    series={props.seriesForTrainerPieChart}
    height={props.size ? props.size : "100%"}
    type="donut"
  />
  )
}