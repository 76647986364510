import * as React from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import BrushTwoToneIcon from "@mui/icons-material/BrushTwoTone";
import IconButton from "@mui/material/IconButton";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import PeopleTwoToneIcon from "@mui/icons-material/PeopleTwoTone";
import VpnKeyTwoToneIcon from "@mui/icons-material/VpnKeyTwoTone";
import AdminPanelSettingsTwoToneIcon from "@mui/icons-material/AdminPanelSettingsTwoTone";
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../Users/UserContext";

export default function Settingsmenu() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const navigate = useNavigate();
  const { currentUserData } = React.useContext(UserContext);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    //navigate("./"+settingsConfig[event.target.value])
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div>
      <IconButton
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <SettingsTwoToneIcon style={{ width: "32px", height: "32px" }} />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  {currentUserData.permissions.includes(22) && (
                    <MenuItem onClick={() => handleClose + navigate("./users")}>
                      <PeopleTwoToneIcon sx={{ marginRight: "10px" }} />
                      Benutzer
                    </MenuItem>
                  )}
                  {currentUserData.permissions.includes(23) && (
                    <MenuItem
                      onClick={() => handleClose + navigate("./lookandfeel")}
                    >
                      {" "}
                      <BrushTwoToneIcon sx={{ marginRight: "10px" }} />
                      Look & Feel
                    </MenuItem>
                  )}
                  {currentUserData.permissions.includes(20) && (
                    <MenuItem onClick={() => handleClose + navigate("./sso")}>
                      {" "}
                      <VpnKeyTwoToneIcon sx={{ marginRight: "10px" }} />
                      SSO
                    </MenuItem>
                  )}
                  {currentUserData.permissions.includes(21) && (
                    <MenuItem onClick={() => handleClose + navigate("./roles")}>
                      {" "}
                      <AdminPanelSettingsTwoToneIcon
                        sx={{ marginRight: "10px" }}
                      />
                      Rollen
                    </MenuItem>
                  )}
                {currentUserData.permissions.includes(24) && (
                    <MenuItem onClick={() => handleClose + navigate("./locations")}>
                      {" "}
                      <LocationOnTwoToneIcon
                        sx={{ marginRight: "10px" }}
                      />
                      Standorte
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
