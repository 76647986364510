import React, { useContext } from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { transparentize } from "polished";
import { UserContext } from "../Users/UserContext";
import Menu from "@mui/material/Menu";
import * as Icons from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import { ActiveNavigationContext } from "./Sidebar";
import getSidebarConfig from "./sidebarConfig";
import { useTransition } from 'react';

export default function SidebarMenuMini(props) {
  const { currentUserData } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenus, setOpenMenus] = React.useState([]);
  const { activeNavigation } = useContext(ActiveNavigationContext);
  let selectedPage = activeNavigation.activePage;
  const navigate = useNavigate();
  var theme = useTheme();
  let sidebarConfig = getSidebarConfig(props.module);
  const [isPending, startTransition] = useTransition();

  const handleMenuClick = (event, menuId) => {
    setAnchorEl(event.currentTarget);
    const isOpen = openMenus.includes(menuId);
    setOpenMenus((prevOpenMenus) =>
      isOpen
        ? prevOpenMenus.filter((id) => id !== menuId)
        : [...prevOpenMenus, menuId]
    );
  };

  const handleListItemClick = (event, route, id) => {
    startTransition(() => {
      setOpenMenus([]);
      navigate(route);
    });
  };

  const themeSidebarMini = createTheme({
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            margin: "10px !important",
            color: "rgb(99, 115, 129)",
            "&.Mui-selected": {
              color: theme.palette.primary.main,
              backgroundColor: transparentize(0.85, theme.palette.primary.main),
            },
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            marginLeft: "10px !important",
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            display: "none",
          },
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            display: "none",
          },
        },
      },
    },
  });

  const hasPermission = (permissions) => {
    return permissions.some(permission => currentUserData.permissions.includes(permission));
  };

  const generateMenuItems = (menuItems) => {
    return menuItems.map((menuItem) => {
      const { id, icon, text, route, subMenu, permission } = menuItem;
      const IconComponent = Icons[icon];
      if (subMenu) {
        if (hasPermission(subMenu.map(item => item.permission))) {
          const isOpen = openMenus.includes(id);
          return (
            <div key={id}>
              <Tooltip title={text} placement="right">
                <MenuItem
                  id={id}
                  selected={selectedPage === id}
                  onClick={(event) => handleMenuClick(event, id)}
                >
                  <ListItemIcon>
                    {IconComponent && <IconComponent />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                  {isOpen ? <ExpandLess /> : <ExpandMore />}
                </MenuItem>
              </Tooltip>
              <Menu
                onClose={() => setOpenMenus([])}
                open={isOpen}
                anchorEl={anchorEl}
              >
                {subMenu.map((subMenuItem) => (
                  currentUserData.permissions.includes(subMenuItem.permission) &&
                  <MenuItem
                    key={subMenuItem.id}
                    onClick={(event) =>
                      handleListItemClick(event, subMenuItem.route, id)
                    }
                  >
                    {subMenuItem.text}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          );
        }
      } else if (currentUserData.permissions.includes(permission)) {
        return (
          <Tooltip title={text} placement="right">
            <MenuItem
              key={id}
              id={id}
              selected={selectedPage === id}
              onClick={(event) => handleListItemClick(event, route, id)}
            >
              <ListItemIcon>{IconComponent && <IconComponent />}</ListItemIcon>
              <ListItemText primary={text} />
            </MenuItem>
          </Tooltip>
        );
      }
    });
  };

  return (
    <ThemeProvider theme={themeSidebarMini}>
      <MenuList
        component="nav"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Feedback
          </ListSubheader>
        }
      >
        {sidebarConfig.main && generateMenuItems(sidebarConfig.main)}
      </MenuList>

      {currentUserData.permissions.some(permission => permission >= 10 && permission <= 19) && sidebarConfig.settings.length > 0 && (
        <MenuList
          component="nav"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Administration
            </ListSubheader>
          }
        >
          {generateMenuItems(sidebarConfig.settings)}
        </MenuList>
      )}
    </ThemeProvider>
  );
}
