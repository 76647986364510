import { sendGetRequest } from "../../Common/sendGetRequest";

export const RadialDiagram = {
  series: [],
  options: {
    type: "bar",
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 4,
        distributed: true,
      },
      radialBar: {
        dataLabels: {
          value: {
            fontSize: "19px",
            formatter: function (val) {
              return ((val * 5) / 100).toFixed(1) + " \u2605";
            },
          },
          total: {
            show: true,
            label: "Durchschnitt",
            fontWeight: 500,
            formatter: function (w) {
              return (
                (
                  ((w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0) /
                    w.globals.series.length) *
                    5) /
                  100
                ).toFixed(1) + " \u2605"
              );
            },
          },
        },
      },
    },
    labels: [],
    legend: {
      show: true,
      position: "bottom",
    },
  },
};
// sendGetRequest("categories").then((response) => {
//   RadialDiagram.options.labels = Object.values(response).map(
//     (item) => item.Category
//   );
// });
