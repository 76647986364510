import { toast } from "react-toastify";
import { baseURL } from "./baseURL.js";
import { getCookie } from "./getCookie.js";

export const sendDeleteRequest = async (endpoint, data) => {
  const jwt = getCookie("jwt");
  const endpointUrl = baseURL + endpoint;
  
  try {
  const res = await fetch(endpointUrl, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(data)
  });
 
  if (!res.ok) {
    toast.error(
      "Ein unbekannter Fehler ist aufgetreten"
    );
  }
  return res;

}
catch (error) {
  if (!error.message.includes("Token")) {
    toast.error(
      "Ein Fehler ist aufgetreten, überprüfe deine Internetverbindung"
    );
  }
  throw error;
}
};

