
import { DonutDiagram } from "../../../Diagrams/DonutDiagram";
import ReactApexChart from "react-apexcharts";


export default function CoursesDiagram(props) {

  const courseDonutDiagram = JSON.parse(JSON.stringify(DonutDiagram));
  courseDonutDiagram.options.labels = props.labelsForCoursePieChart;
  courseDonutDiagram.options.title.text = props.header;
  courseDonutDiagram.options.plotOptions.pie.donut.labels.total.label = "Total";
  courseDonutDiagram.options.colors = [];

  if(props.seriesForCoursePieChart.length>4)
  {
    courseDonutDiagram.options.legend.height=40;
  }

  return(
    <ReactApexChart
    options={courseDonutDiagram.options}
    series={props.seriesForCoursePieChart}
    height={props.size ? props.size : "100%"}
    type="donut"
  />
  )
}