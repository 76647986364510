import React from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import "survey-core/survey.i18n";
import { sendPostRequestWoToken } from "./sendPostRequestWoToken";
import { themeJson } from "./theme";
import * as SurveyCore from "survey-core";
import { nouislider } from "surveyjs-widgets";
import "./nouislider.css";

function insertFeedback(survey, trainer, course, location, courseNumber, surveyData, surveyJson, target, pages){
  const mergedJSON = { surveyData:{...surveyData}, surveyJson:{...surveyJson} };
  sendPostRequestWoToken("feedback/insert?survey="+survey+"&trainer="+trainer+"&course="+course+"&location="+location+"&coursenumber="+courseNumber+"&target="+target+"&pages="+pages, mergedJSON)
  // sendPostRequestWoToken("insertFeedback.php?survey="+survey+"&trainer="+trainer+"&course="+course+"&target="+target, mergedJSON)
}

nouislider(SurveyCore);

function SurveyJs(props) {
  const uniqueSurveyJson = renameDuplicateQuestionNames(props.json);
  const survey = new Model(uniqueSurveyJson);
  themeJson.cssVariables["--sjs-primary-backcolor"]=props.primaryBackcolor;
  survey.applyTheme(themeJson);
  survey.showCompleteButton = !props.preview;
  survey.cookieName ="survey"
  survey.onComplete.add((sender, options) => {
    var surveyData = sender.data;
    survey.pages.forEach(function (page) {
      page.questions.forEach(function (question) {
        var questionName = question.name;
        if (!surveyData.hasOwnProperty(questionName)) {
          surveyData[questionName] = null;
        }
      });
    });
    props.json.pages.shift();
    insertFeedback(props.surveyId, props.trainer, props.course, props.location, props.courseNumber, surveyData, props.json, props.target, props.pages)
  });
  return <Survey model={survey} className={props.class}/>;
}

function renameDuplicateQuestionNames(surveyJson) {
  const nameCount = {};

  surveyJson.pages.forEach((page) => {
    page.elements.forEach((element) => {
      const name = element.name;

      // Wenn der Name bereits existiert, erhöhe den Zähler und hänge einen Buchstaben an
      if (nameCount[name]) {
        const newSuffix = String.fromCharCode(96 + nameCount[name]); // 96 + 1 = 'a', 96 + 2 = 'b', usw.
        const newName = `${name}_${newSuffix}`;
        
        element.name = newName;
        nameCount[name] += 1; // Erhöhe den Zähler für den nächsten potenziellen Konflikt

        console.log(`Duplicate name found. Renamed "${name}" to "${newName}"`);
      } else {
        // Erstelle den Eintrag für diesen Namen mit Startwert 1
        nameCount[name] = 1;
      }
    });
  });

  return surveyJson;
}

export default SurveyJs;
