export function berechneDurchschnittBewertung(data) {
  let sum = 0;
  let count = 0;

  data.forEach((item) => {
    if (item.Typ === "Bewertung") {
      const bewertung = parseFloat(item.Value);
      if (!isNaN(bewertung)) {
        sum += bewertung;
        count++;
      }
    }
  });

  // Schutz vor Division durch Null
  if (count === 0) {
    return 0;
  }
  const durchschnitt = sum / count;
  // Runden auf eine Dezimalstelle
  const gerundeterDurchschnitt = durchschnitt.toFixed(1);

  return parseFloat(gerundeterDurchschnitt); // Als Zahl zurückgeben
}

export function createSeriesForEvaluationPieChart(data) {
  let evaluationSeries = [0, 0, 0, 0, 0];
  data.forEach((item) => {
    if (item.Typ === "Bewertung") {
      const bewertung = parseFloat(item.Value);
      if (!isNaN(bewertung)) {
        evaluationSeries[bewertung - 1] += 1;
      }
    }
  });
  return evaluationSeries.reverse();
}

export function formatDate(dateString) {
  let date = new Date(dateString);
  
  let day = date.getDate();
  let month = date.getMonth() + 1; 
  let year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  
  if (day < 10) day = '0' + day;
  if (month < 10) month = '0' + month;
  if (hours < 10) hours = '0' + hours;
  if (minutes < 10) minutes = '0' + minutes;
  
  return day + '.' + month + '.' + year + ' ' + hours + ':' + minutes;
}


export function createSeriesForRadialChart(data, newRadial, Property, PropertyName) {
  let evaluationSeries = [];
  let maxSeries = [];
  const uniqueCategoriesSet = new Set(
    data.filter((item) => item[Property] !== null && item.Typ === "Bewertung").map((item) => item[Property])
  );
  const uniqueCategoriesArray = [...uniqueCategoriesSet];
  const CategoriesNameSet = new Set(
    data.filter((item) => item[Property] !== null  && item.Typ === "Bewertung").map((item) => item[PropertyName])
  );
  const CategoriesNameArray = [...CategoriesNameSet];
  newRadial.options.labels=CategoriesNameArray;
  if(CategoriesNameArray.length===1){
    newRadial.options.plotOptions.radialBar.dataLabels.total.label=CategoriesNameArray[0]
  }
  else{
    newRadial.options.plotOptions.radialBar.dataLabels.total.label="Durchschnitt"
  }
  uniqueCategoriesArray.forEach(() => evaluationSeries.push(0));
  uniqueCategoriesArray.forEach(() => maxSeries.push(0));
  data.forEach((item) => {
    if (item.Typ === "Bewertung") {
      const bewertung = parseFloat(item.Value);
      const index = uniqueCategoriesArray.indexOf(item[Property]);
      if (!isNaN(bewertung)) {
        evaluationSeries[index] += bewertung;
        maxSeries[index] += 5;
      }
    }
  });
  const modifiedArray = evaluationSeries.map(
    (value, index) => (value / maxSeries[index]) * 100
  );
  return modifiedArray;
}

export function arrayWithObjectsNameSort(arrayToSort) {
  arrayToSort.sort((a, b) => {
    const nameA = a.name.toUpperCase(); // Convert to uppercase for case-insensitive sorting
    const nameB = b.name.toUpperCase();

    if (nameA < nameB) {
      return -1;
    } else if (nameA > nameB) {
      return 1;
    } else {
      return 0;
    }
  });
}

export function arraySortFirstElement(arrayToSort) {
  arrayToSort.sort((a, b) => {
    const nameA = a[0].toUpperCase(); // Convert to uppercase for case-insensitive sorting
    const nameB = b[0].toUpperCase();

    if (nameA < nameB) {
      return -1;
    } else if (nameA > nameB) {
      return 1;
    } else {
      return 0;
    }
  });
}

export function createObjectForTrainerPieChart(data) {
  const feedbackIDs = {};
  const feedbackSeries = {};

  data.forEach((feedback) => {
    const trainerID = feedback.Trainer;
    const trainerName = feedback.TrainerName;
    const feedbackID = feedback.id;

    if (!(feedbackID in feedbackIDs)) {
      if (trainerID in feedbackIDs) {
        feedbackSeries[trainerName]++;
      } else {
        feedbackIDs[trainerID] = true;
        feedbackSeries[trainerName] = 1;
      }
      feedbackIDs[feedbackID] = true;
    }
  });
  return feedbackSeries;
}

export function createObjectForCoursePieChart(data) {
  const feedbackIDs = {};
  const feedbackSeries = {};

  data.forEach((feedback) => {
    const courseID = feedback.Course;
    const courseName = feedback.CourseName;
    const feedbackID = feedback.id;

    if (!(feedbackID in feedbackIDs)) {
      if (courseID in feedbackIDs) {
        feedbackSeries[courseName]++;
      } else {
        feedbackIDs[courseID] = true;
        feedbackSeries[courseName] = 1;
      }
      feedbackIDs[feedbackID] = true;
    }
  });
  return feedbackSeries;
}

export function combineAndAverageFeedback(feedbackArray) {
  const combinedFeedback = {};

  feedbackArray.forEach((feedback) => {
    if (feedback.Typ === "Bewertung" && !isNaN(feedback.Value)) {
      const feedbackID = feedback.id;
      const date = new Date(feedback.Date);
      // Create a combined key of feedbackID and date
      const combinedKey = `${date.getYear()}-${date.getMonth()}`; //Hier wird die Granularität der Auswertung definiert

      if (!combinedFeedback[combinedKey]) {
        // If the combinedFeedback object doesn't have an entry for this combined key, create one
        combinedFeedback[combinedKey] = {
          Typ: feedback.Typ,
          Date: date,
          id: feedbackID,
          Value: parseFloat(feedback.Value), // Assuming Value is a numeric property
          Count: 1,
        };
      } else {
        // If the entry already exists, update the average and count
        combinedFeedback[combinedKey].Value += parseFloat(feedback.Value);
        combinedFeedback[combinedKey].Count++;
      }
    }
  });

  // Calculate the average for each entry
  for (const combinedKey in combinedFeedback) {
    combinedFeedback[combinedKey].Value /= combinedFeedback[combinedKey].Count;
  }

  // Convert the combinedFeedback object back to an array
  const resultArray = Object.values(combinedFeedback);

  // Custom sorting export function for datetime
  function compareDatetime(a, b) {
    const dateA = new Date(a.Date);
    const dateB = new Date(b.Date);
    return dateA - dateB;
  }

  // Sort the array using the custom export function
  resultArray.sort(compareDatetime);
  return resultArray;
}

export function createObjectForTimelineChart(data) {
  data = combineAndAverageFeedback(data);
  const feedbackData = [];

  data.forEach((feedback) => {
    if (feedback.Typ === "Bewertung" && !isNaN(feedback.Value)) {
      const myDate = new Date(feedback.Date);

      const year = myDate.getFullYear();
      const month = myDate.getMonth() + 1; // Adding 1 to get a one-based month

      // Format the result as 'YYYY-MM'
      const yearMonthString = `${year}-${month.toString().padStart(2, "0")}`;

      // Create a new Date object from the formatted string
      const yearMonthDate = new Date(yearMonthString);
      const newFeedback = {
        x: yearMonthDate,
        y: parseFloat(feedback.Value).toFixed(1),
      };
      feedbackData.push(newFeedback);
    }
  });
  
  return feedbackData;
}

export function getNumberForTimelineChart(data) {
  const feedbackData = [];
  const yearMonthArray = [];
  const feedbackIDs = [];
  let i = 0;

  data.forEach((feedback) => {
    if (!feedbackIDs.includes(feedback.id)) {
      feedbackIDs.push(feedback.id);
      const myDate = new Date(feedback.Date);
      const year = myDate.getFullYear();
      const month = myDate.getMonth() + 1; // Adding 1 to get a one-based month
      const yearMonthString = `${year}-${month.toString().padStart(2, "0")}`;

      const yearMonthDate = new Date(yearMonthString);
      if (!yearMonthArray.includes(yearMonthString)) {
        yearMonthArray.push(yearMonthString);
        const newFeedback = {
          x: yearMonthDate,
          y: 1,
        };
        feedbackData[i + 1] = newFeedback;
        i++;
      } else {
        feedbackData[i].y++;
      }
    }
  });
  const filteredArray = feedbackData.filter(Boolean).reverse();
  
  return filteredArray;
}

export function countNumberOfFeedback(data) {
  const uniqueFeedbackIDs = new Set(data.map((item) => item.id));
  return uniqueFeedbackIDs.size;
}

export function countNumberOfTrainers(data) {
  const uniqueTrainers = new Set(data.map((item) => item.Trainer));
  return uniqueTrainers.size;
}

export function countNumberOfCourses(data) {
  const uniqueCourses = new Set(data.map((item) => item.Course));
  return uniqueCourses.size;
}

export function createSeriesForTrainerPieChart(inputObject) {
  const namesArray = [];
  const numbersArray = [];

  for (const [name, number] of Object.entries(inputObject)) {
    namesArray.push(name);
    numbersArray.push(number);
  }

  return { labels: namesArray, series: numbersArray };
}

export function createSeriesForCoursePieChart(inputObject) {
  const namesArray = [];
  const numbersArray = [];

  for (const [name, number] of Object.entries(inputObject)) {
    namesArray.push(name);
    numbersArray.push(number);
  }

  return { labels: namesArray, series: numbersArray };
}

export function calculateDaysSinceLastFeedback(data) {
  let latestDate = new Date(data[0].Date);
  let today = new Date();

  const timeDifferenceInMilliseconds = new Date() - latestDate;

  const timeDifferenceInDays = Math.floor(
    timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24)
  );

  let textToShow = `vor ${timeDifferenceInDays} Tagen`;
  if (timeDifferenceInDays === 0 || timeDifferenceInDays === 1) {
    if (today.getDate() != latestDate.getDate()) {
      textToShow = "gestern";
    } else textToShow = "heute";
  }

  return textToShow;
}

export function Next(diagramID) {
  const nextButton = document.querySelector(
    diagramID + ">.carousel-control-next"
  );
  if (nextButton) {
    nextButton.click();
  }
}

export function Prev(diagramID) {
  const nextButton = document.querySelector(
    diagramID + ">.carousel-control-prev"
  );
  if (nextButton) {
    nextButton.click();
  }
}
