import { sendGetRequest } from "../../Common/sendGetRequest";

export const DonutDiagram = {
  series: [100, 0, 0, 17, 15],
  options: {
    colors: [
      "#008000", /* Dark Green */
      "#adff2f", /* Green */
      "#ffd700", /* Yellow */
      "#ff8c00", /* Orange */
      "#ff0000", /* Red */
    ],
    type: "donut",
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "",
      align: "left",
    },
    plotOptions: {
      pie: {
        donut: {
          size: "70%", //wie groß ist das Loch
          labels: {
            show: true,
            total: {
              showAlways: false,
              show: true,
              label: "Durchschnitt",
              fontWeight: 500,
              formatter: function (w) {
                const sum = w.globals.seriesTotals
                  .reverse()
                  .reduce((a, b, index) => a + b * (index + 1), 0);
                const number = w.globals.seriesTotals
                  .reverse()
                  .reduce((a, b) => a + b, 0);
                const textToShow =
                  number != 0
                    ? Math.round((sum / number) * 10) / 10 + " \u2605"
                    : "Es wurde keine Bewertung abgegeben";
                return textToShow;
              },
            },
          },
        },
      },
    },
    legend: {
      position: "bottom",
    },
  },
};
sendGetRequest("system/evaluationOptions").then((response) => {
  DonutDiagram.options.labels = Object.values(response).map(
    (item) => item.Answer
  );
});
