import titleimage from "../../Assets/evaluation.png"
import { introConfig } from "./introConfig";

export function evaluationIntro() {
  const config = introConfig(); // Get the configuration object

  return {
    ...config,
    steps: [
      {
        title: "Fragen Auswertung  <img src="+titleimage+" width=35 height=35/>",
        intro: "Erhalte eine detailierte Auswertung des Feedbacks zu einzelnen Fragen",
      },
      {
        title: "Filter 🚩",
        element: document.querySelector("#filter-button"),
        intro: "Klicke hier um das abgegebene Feedback nach Kriterien wie Trainer, Kurse oder Fragenkategorien zu filtern",
      },
      {
        title: "Zielgruppe 👁️",
        element: document.querySelector(".MuiButtonGroup-root"),
        intro: "Wechsle zwischen den Zielgruppen Kursteilnehmer und interne Mitarbeiter um die Auswertung zu filtern",
      },
      {
        title: "Fragen Auswertung 📈",
        element: document.querySelector(".MuiDataGrid-root"),
        intro: "In einer Tabelle werden alle Fragen zu denen Feedback abgegeben wurde gelistet. Durch Klick auf Details wird eine Auswertung der gewählten Frage geladen",
      },
    ],
  };
}
