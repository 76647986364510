import { createContext, useEffect, useState } from "react";
import { sendGetRequest } from "../../Common/sendGetRequest";
import Spinner from "../../Common/Spinner";

export const UserContext = createContext({});

export const UserProvider = ({ children }) => {
  const [currentUserData, setCurrentUserData] = useState({
    id: 0,
    name: "",
    vorname: "",
    nachname: "",
    email: "",
    role: 0,
    permissions: [],
    description: "ohne Berechtigung",
    avatarfile: "",
    notification: 0,
  });
  const [allFeedbackData, setAllFeedbackData] = useState([])
  const [dataFetched, setDataFetched] = useState(false)
  const [isLoading, setIsLoading] = useState(true);

  const updateCurrentUserState = (variableName, value) => {
    setCurrentUserData(prevState => ({
      ...prevState,
      [variableName]: value
    }));
  };

  useEffect(() => {
    let mounted = true;
    
    const loadUserData = async () => {
      try {
        setIsLoading(true);
        const decoded = await sendGetRequest("user/context");
        if (mounted && decoded) {
          setCurrentUserData(prevState => ({
            ...prevState,
            id: decoded.id,
            name: decoded.Vorname + " " + decoded.Nachname,
            vorname: decoded.Vorname,
            nachname: decoded.Nachname,
            email: decoded.Email,
            role: decoded.Role,
            permissions: decoded.Permissions,
            description: decoded.Description,
            notification: decoded.Notification,
            avatarfile: decoded.AvatarFile,
          }));
        }
      } catch (e) {
        console.error(e);
      } finally {
        if (mounted) {
          setIsLoading(false);
        }
      }
    };

    loadUserData();
    return () => { mounted = false; };
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <UserContext.Provider value={{ 
      currentUserData, 
      allFeedbackData, 
      updateCurrentUserState, 
      dataFetched, 
      isLoading 
    }}>
      {children}
    </UserContext.Provider>
  );
};
