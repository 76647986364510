import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import ErrorpageImage from "../Assets/errorpage.svg";
import Stack from "@mui/material/Stack";
import { sendPostRequest } from "./sendPostRequest";

export default function ErrorPage({error}) {

  console.log("error occured: ", error.message)
  const copyToClipboard = () => {
    navigator.clipboard.writeText(error.message);
  };

  useEffect(() => {
    sendPostRequest("system/error", error.message);
  }, []); 

  return (
    <div style={{backgroundColor:"white", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", padding:"0px 20px"}}>
      <img src={ErrorpageImage} style={{maxHeight:"80vh", maxWidth:"100vw"}} alt="Error Page" />
      <p style={{margin:"-60px 0px 30px", maxWidth:"1000px"}}>Ein unerwarteter Fehler ist aufgetreten. Sollte der Fehler häufiger auftreten, kontaktiere bitte unseren <a href= "https://feedcube.atlassian.net/servicedesk/customer/portal/2/group/12/create/26">Support</a></p>
      <Stack direction= "row" flexWrap="true" spacing={3}>
      <Button variant = "outlined" onClick={copyToClipboard}>
          Fehler kopieren
      </Button>
      <Button variant = "contained" onClick={() => (window.location.href = "/dashboard")}>
          Zum Dashboard
        </Button>
      </Stack>
    </div>
  );
}
