import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import { FaUserGraduate } from "react-icons/fa";
import TranscribeIcon from "@mui/icons-material/Transcribe";
import useQueryParams from "../../../Common/useQueryParams";

export default function TargetButton(props) {
  const { updateQueryObject } = useQueryParams();

  return (
    <ButtonGroup
      style={{ width: "100px" }}
      id="target-button"
      variant="outlined"
      aria-label="outlined primary button group"
    >
      <Tooltip title="Kursteilnehmer Feedback">
        <Button
          variant={props.target === "Kursteilnehmer" ? "contained" : "outlined"}
          onClick={() => {
            props.setTarget("Kursteilnehmer");
            props.setSurveyId("");
            props.setSurveyName("");
            updateQueryObject({ target: "Kursteilnehmer", survey: null });
          }}
        >
          <FaUserGraduate size={"20px"} />
        </Button>
      </Tooltip>
      <Tooltip title="Internes Feedback">
        <Button
          variant={props.target === "Intern" ? "contained" : "outlined"}
          onClick={() => {
            props.setTarget("Intern");
            props.setSurveyId("");
            props.setSurveyName("");
            updateQueryObject({ target: "Intern", survey: null });
          }}
        >
          <TranscribeIcon size={"20px"} />
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
}
