import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import { UserContext } from "../Users/UserContext";
import UserAvatar from "../Users/UserAvatar";
import { useContext } from "react";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ManageAccountsTwoToneIcon from "@mui/icons-material/ManageAccountsTwoTone";
import MeetingRoomTwoToneIcon from "@mui/icons-material/MeetingRoomTwoTone";
import { logout } from "../../Authentication/logout";
import Settingsmenu from "./Settingsmenu";
import { useTransition } from 'react';

export default function Navbar(props) {
  const navigate = useNavigate();
  const [isPending, startTransition] = useTransition();

  const themeSidebarOpen = createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            width: "200px !important",
            top: "50px !important",
            right: "40px !important",
            left: "auto !important",
          },
        },
      },
    },
  });

  const { currentUserData } = useContext(UserContext);
  var collapsed = true;
  var avatarSize = 38;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer - 1,
    ...(open && {
      width: `calc(100%)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <ThemeProvider theme={themeSidebarOpen}>
      <Menu open={isMenuOpen} onClose={handleMenuClose}>
        <UserAvatar
          name={currentUserData.name}
          src={currentUserData.avatarfile}
          height={120}
          width={120}
          marginLeft={"auto"}
          marginRight={"auto"}
          marginTop={"20px"}
          marginBottom={"10px"}
        />
        <ListItemText
          style={{ margin: "auto", textAlign: "center", marginBottom: "20px" }}
          primary={currentUserData.name}
          secondary={currentUserData.description}
        />
        <MenuItem onClick={() => {
          startTransition(() => {
            handleMenuClose();
            navigate("/profile");
          });
        }}>
          <ListItemIcon>
            <ManageAccountsTwoToneIcon />
          </ListItemIcon>
          <ListItemText primary="Profil" />
        </MenuItem>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <MeetingRoomTwoToneIcon />
          </ListItemIcon>
          <ListItemText primary="Abmelden" />
        </MenuItem>
      </Menu>
    </ThemeProvider>
  );

  return (
    <AppBar
      sx={{
        backgroundColor: "#fbfbff",
        color: "rgb(99, 115, 129)",
        opacity: 0.97,
      }}
      elevation={0}
      open={props.open}
    >
      <Toolbar style={{ display: "flex", height: "56px" }}>
        {window.innerWidth < 1100 && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => props.setOpen(true)}
            edge="start"
            sx={{
              width: "20px",
              marginLeft: "5px",
              marginRight: 5,
              ...(props.open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Box style={{ display: "flex", gap:"10px", justifyContent: "end", alignItems: "center", width: "98%" }}>
          {/* <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
          >
            <Badge badgeContent={17} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          {currentUserData.permissions.some(permission => permission >= 20) && <Settingsmenu/>}
          {/* {window.innerWidth < 1100 ? (
            <ModuleSelectMobile
              module={props.activeNavigation.activeModule}
              setModule={props.setActiveNavigation}
            />
          ) : (
            <ModuleSelect
              module={props.activeNavigation.activeModule}
              setModule={props.setActiveNavigation}
            />
          )} */}
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <UserAvatar
              name={currentUserData.name}
              src={currentUserData.avatarfile}
              height={avatarSize}
              width={avatarSize}
              marginLeft={"auto"}
              marginRight={"auto"}
              marginTop={collapsed ? "0px" : "5px"}
              marginBottom={collapsed ? "0px" : "10px"}
            />
            <p
              style={{
                margin: "auto",
                marginBottom: "0px",
                textAlign: "center",
                display: !collapsed ? "block" : "none",
              }}
            >
              {currentUserData.name}
            </p>
            <p
              style={{
                color: "rgb(154, 153, 153) !important",
                fontSize: "14px",
                margin: "auto",
                marginBottom: "20px",
                textAlign: "center",
                display: !collapsed ? "block" : "none",
              }}
            >
              {currentUserData.description}
            </p>
          </IconButton>
        </Box>
      </Toolbar>
      {renderMenu}
    </AppBar>
  );
}
