import { toast } from "react-toastify";
import { sendGetRequest } from "../../Common/sendGetRequest";
import { sendPostRequest } from "../../Common/sendPostRequest";
import { sendDeleteRequest } from "../../Common/sendDeleteRequest";
import { showToaster } from "../../Common/showToaster";

export const deleteSurvey = (id, setData, setDataFetched) => {
  sendDeleteRequest(`survey/deleteSurvey?id=${id}`).then((response) => {
    if (response.ok) {
      toast.success("Fragebogen wurde erfolgreich gelöscht");
      getAllSurveys(setData, setDataFetched);
    }
  });
};

export function deleteValidationErrors(questionData) {
  const errors = {};

  Object.entries(questionData).forEach(([key, value]) => {
    errors[`error${key.charAt(0).toUpperCase()}${key.slice(1)}`] = false;
  });
}

export function validateInputFields(
  questionData,
  setErrorQuestionData,
  updateState
) {
  var isValid = true;
  if (questionData.selectedOption === "") {
    updateState("errorSelectedOption", true, setErrorQuestionData);
    isValid = false;
  }
  if (questionData.description === "") {
    updateState("errorDescription", true, setErrorQuestionData);
    isValid = false;
  }
  if (questionData.descriptionEnglish === "") {
    updateState("errorDescriptionEnglish", true, setErrorQuestionData);
    isValid = false;
  }
  if (
    (questionData.selectedOption == 1 ||
      questionData.selectedOption == 2 ||
      questionData.selectedOption == 5 ||
      questionData.selectedOption == 6) &&
    questionData.customOptions == ""
  ) {
    updateState("errorCustomOptions", true, setErrorQuestionData);
    isValid = false;
  }

  return isValid;
}

export function editSurvey(surveyData, surveyId, setSuccess, setLoading) {
  sendPostRequest("survey/editSurvey?id=" + surveyId, surveyData).then(
    (response) => {
      if (!response.ok) {
        setLoading(false);
        toast.error(
          "Ein unerwarteter Fehler ist aufgetreten, bitte überprüfe die Internetverbindung"
        );
      }
    }
  );
}

export function sortArray(array, order) {
  var orderedArray = [];
  order.forEach((element) => {
    orderedArray.push(array[element - 1]);
  });
  return orderedArray;
}

export function sortJson(json, order) {
  console.log("Json", json);
  let newJson = { ...json }; // Shallow copy of the original JSON
  newJson.pages = [json.pages[0]];
  order.forEach((element) => {
    newJson.pages.push(json.pages[element]);
  });
  newJson.pages.push(json.pages[json.pages.length - 1]);
  console.log("newJson", newJson);
  return newJson;
}

export function checkNoPagesEmpty(array, maxpage) {
  if (array.length === 0 || array.length < maxpage) {
    return false; // Return false if the main array is empty
  }

  for (var subarray of array) {
    if (!subarray || subarray.length === 0) {
      return false; // Return false if any subarray is empty
    }
  }

  return true; // Return true if no empty subarray is found
}

export async function createSurvey(
  surveyData,
  setSuccess,
  setLoading,
  navigate
) {
  try {
    const response = await sendPostRequest("survey/createSurvey", surveyData);
    const responseObject = await response.json();
    if (response.ok) {
      setSuccess(true);
      setLoading(false);
      localStorage.setItem(
        "toastMessage",
        "Fragebogen wurde erfolgreich angelegt"
      );
      localStorage.setItem("activeStepSet", 1);
      navigate("../surveys/edit/" + responseObject.surveyId);
      return responseObject.surveyId;
    } else {
      setLoading(false);
    }
  } catch (error) {
    setLoading(false);
    toast.error(
      "Ein unerwarteter Fehler ist aufgetreten, bitte überprüfe die Internetverbindung"
    );
  }
}

export async function updateHeaders(surveyData, surveyId) {
  if (surveyId) {
    surveyData = {
      ...surveyData,
      surveyId: surveyId,
    };
  }

  sendPostRequest("survey/header/edit", surveyData);
}

export function removePage(
  page,
  surveyData,
  setSurveyData,
  pagenumber,
  setPagenumber,
  setPage,
  surveyId
) {
  sendDeleteRequest(
    `survey/removeSurveyPage?surveyId=${surveyId}&page=${page}`
  ).then((response) => {
    if (response.ok) {
      toast.success("Seite wurde erfolgreich gelöscht");
    }
  });
  var temparray = surveyData.surveyRelatedQuestionList.splice(
    parseInt(page) - 1,
    1
  );

  if (temparray.length > 0 && temparray[0] !== undefined) {
    temparray = temparray[0].concat(surveyData.surveyUnrelatedQuestionList);
    temparray.sort((a, b) => a.Reihenfolge - b.Reihenfolge);
    updateState("surveyUnrelatedQuestionList", temparray, setSurveyData);
  }

  updateState(
    "surveyRelatedQuestionList",
    surveyData.surveyRelatedQuestionList,
    setSurveyData
  );

  surveyData.headers.splice(parseInt(page) - 1, 1);
  updateState("headers", surveyData.headers, setSurveyData);

  setPagenumber(pagenumber - 1);
  setPage(1);
}

export function copyPage(
  page,
  surveyData,
  setSurveyData,
  pagenumber,
  setPagenumber,
  setPage,
  surveyId
) {
  sendPostRequest(`survey/copySurveyPage?id=${surveyId}&page=${page}`).then(
    (response) => {
      if (response.ok) {
        toast.success("Seite wurde erfolgreich kopiert");
      }
    }
  );
  surveyData.surveyRelatedQuestionList.splice(
    page,
    0,
    surveyData.surveyRelatedQuestionList[page - 1]
  );
  surveyData.headers.splice(page, 0, surveyData.headers[page - 1]);
  console.log("surveyData: ", surveyData);
  setPagenumber(parseInt(pagenumber) + 1);
  setPage(parseInt(page) + 1);
}

export const updateState = (variableName, value, setData) => {
  setData((prevState) => ({
    ...prevState,
    [variableName]: value,
  }));
};

export function getAllSurveys(setRows, setDataFetched) {
  sendGetRequest("surveys").then(
    (response) => setRows(response) + setDataFetched(true)
  );
}
