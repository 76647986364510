import DateRangePicker from "react-bootstrap-daterangepicker";
import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useTheme } from "@mui/material/styles";

export default function DateRange(props) {
  const theme = useTheme();

  const currentDate = new Date();
  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);

  const lastSevenDays = new Date(currentDate);
  lastSevenDays.setDate(currentDate.getDate() - 7);

  const lastThirtyDays = new Date(currentDate);
  lastThirtyDays.setDate(currentDate.getDate() - 30);

  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);
  const lastDayOfYear = new Date(currentDate.getFullYear(), 11, 31);

  function handleInputChange() {
    const inputElement = document.getElementById("dateRange");
    if (!inputElement) {
      console.warn("Element with id 'dateRange' not found.");
      return;
    }

    const inputString = inputElement.value;
    const dateRegex = /(\d{2}\.\d{2}\.\d{4})/g;
    const dates = inputString.match(dateRegex);

    if (dates && dates.length >= 2) {
      const startDate = dates[0];
      const endDate = dates[1];
      props.setStartDate(startDate);
      props.setEndDate(endDate);
    } else {
      console.error("Invalid date input");
    }
  }

  function handleStyleChange() {
    handleInputChange();
  }

  useEffect(() => {
    // Warte, bis das Element im DOM vorhanden ist
    const checkElement = () => {
      const elementToWatch = document.getElementsByClassName("daterangepicker")[0];
      if (elementToWatch) {
        const observer = new MutationObserver(handleStyleChange);
        const config = { attributes: true, attributeFilter: ["style"] };
        observer.observe(elementToWatch, config);
      } else {
        // Falls das Element noch nicht da ist, erneut versuchen
        setTimeout(checkElement, 100);
      }
    };
    checkElement();
  }, []);

  return (
    <div>
      <DateRangePicker
        id="dateRangePicker"
        initialSettings={{
          autoApply: true,
          startDate: props.startDate,
          endDate: props.endDate,
          ranges: {
            Heute: [new Date(), new Date()],
            Gestern: [yesterday, yesterday],
            "letzten 7 Tage": [lastSevenDays, new Date()],
            "letzten 30 Tage": [lastThirtyDays, new Date()],
            "dieser Monat": [firstDayOfMonth, lastDayOfMonth],
            "dieses Jahr": [firstDayOfYear, lastDayOfYear],
          },
          locale: {
            format: "DD.MM.YYYY",
            separator: " - ",
            applyLabel: "Anwenden",
            cancelLabel: "Abbrechen",
            fromLabel: "von",
            toLabel: "bis",
            customRangeLabel: "Benutzerdefiniert",
            weekLabel: "W",
            daysOfWeek: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
            monthNames: [
              "Januar",
              "Februar",
              "März",
              "April",
              "Mai",
              "Juni",
              "Juli",
              "August",
              "September",
              "Oktober",
              "November",
              "Dezember",
            ],
            firstDay: 1,
          },
        }}
      >
        <input
          id="dateRange"
          type="text"
          style={{
            color: theme.palette.primary.main,
            fontFamily: "'Roboto','Helvetica','Arial',sans-serif",
            fontSize: "15px",
            borderRadius: "16px",
            border: "none",
            outline: "none",
          }}
        />
      </DateRangePicker>
    </div>
  );
}
