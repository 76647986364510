import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import { FaQuestion } from "react-icons/fa";
import { dashboardIntro } from "../Intro/dashboardIntro";
import { evaluationIntro } from "../Intro/evaluationIntro";
import introJs from "intro.js";
import "intro.js/introjs.css";
import Tooltip from "@mui/material/Tooltip";
import { surveyIntro } from "../Intro/surveyIntro";
import { userIntro } from "../Intro/userIntro";
import { courseIntro } from "../Intro/courseIntro";
import { surveyAdminIntro } from "../Intro/surveyAdminIntro";
import { questionsIntro } from "../Intro/questionsIntro";

export default function Help() {
  const currentPath = window.location.pathname.slice(1);

  return (
    <>
      {currentPath === "dashboard" ||
      currentPath === "evaluation/questions" ||
      currentPath === "evaluation/surveys" ||
      currentPath === "users" ||
      currentPath === "courses" ||
      currentPath === "surveys" ||
      currentPath === "questions" ? (
        <Tooltip title="Hilfe">
          <IconButton
            id="help-button"
            style={{fontSize: "14px"}}
            size="small"
            aria-label="questions"
            color="inherit"
            onClick={() => {
              switch (currentPath) {
                case "dashboard":
                  introJs().setOption("nextLabel", " > ");
                  introJs().setOptions(dashboardIntro()).start();
                  break;
                case "evaluation/questions":
                  introJs()
                    .setOptions(evaluationIntro("Kursteilnehmer"))
                    .start();
                  break;
                case "evaluation/surveys":
                  introJs().setOptions(surveyIntro("Kursteilnehmer")).start();
                  break;
                case "users":
                  introJs().setOptions(userIntro()).start();
                  break;
                case "courses":
                  introJs().setOptions(courseIntro()).start();
                  break;
                case "surveys":
                  introJs().setOptions(surveyAdminIntro()).start();
                  break;
                case "questions":
                  introJs().setOptions(questionsIntro()).start();
                  break;
                default:
                  break;
              }
            }}
          >
            <FaQuestion />
          </IconButton>
        </Tooltip>
      ) : (
        ""
      )}
    </>
  );
}
