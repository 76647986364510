import React from "react";
import Chart from "react-apexcharts";

export default function CategoryChart({ feedbackData }) {
  // 1) Group by Category and calculate average "Bewertung"
  const categoryMap = new Map();
  feedbackData.forEach((item) => {
    if (item.Typ === "Bewertung") {
      const cat = item.Categoryname || "Unbekannt"; // or some fallback
      const bewertung = parseFloat(item.Value);
      if (!isNaN(bewertung)) {
        if (!categoryMap.has(cat)) {
          categoryMap.set(cat, { sum: 0, count: 0 });
        }
        const categoryData = categoryMap.get(cat);
        categoryData.sum += bewertung;
        categoryData.count += 1;
      }
    }
  });

  // 2) Convert map to arrays and calculate averages
  const categories = Array.from(categoryMap.keys());
  const averages = Array.from(categoryMap.values()).map(
    (data) => (data.sum / data.count).toFixed(1)
  );

  // 3) Combine categories and averages into an array of objects
  const combinedData = categories.map((category, index) => ({
    category,
    average: averages[index],
  }));

  // 4) Sort combined data by average "Bewertung" from high to low
  combinedData.sort((a, b) => b.average - a.average);

  // 5) Extract sorted categories and averages
  const sortedCategories = combinedData.map((data) => data.category);
  const sortedAverages = combinedData.map((data) => data.average);

  // 6) Configure apex chart
  const chartOptions = {
    chart: {
      id: "category-bar",
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,  // <--- Hide legend
    },
    plotOptions: {
      bar: {
        horizontal: true, 
        borderRadius: 4,
        distributed: true,  
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: sortedCategories,
    },
    tooltip: {
      x: {
        formatter: (val) => val, // category
      },
      y: {
        formatter: (val) => `${val} \u2605`,
      },
    },
  };

  // 7) Prepare the data series
  const chartSeries = [
    {
      name: "Durchschnittliche Bewertung",
      data: sortedAverages,
    },
  ];

  // 8) Render
  return (
    <div>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="bar"
        height={300}
      />
    </div>
  );
}
