import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import Navbar from "./Navbar";
import TemporaryDrawer from "./TemporaryDrawer";
import SidebarMenu from "./SidebarMenu";
import SidebarMenuMini from "./SidebarMenuMini";
import FeedcubeIcon from "../../Assets/FeedcubeIcon.svg";
import { getCookie } from "../../Common/getCookie";
import { setCookie } from "../../Common/setCookie";
import { createContext, memo } from "react";

const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `0px`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(11)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const ActiveNavigationContext = createContext({});

const CommonSidebar = memo((props) => {
  let sidebarIsOpen =
    window.innerWidth < 1100
      ? "false"
      : getCookie("sidebarIsOpen")
      ? getCookie("sidebarIsOpen")
      : "true";
  const [open, setOpen] = React.useState(
    sidebarIsOpen === "true" ? true : false
  );
  let activeModuleCookie = getCookie("module") || "Feedback"
  const [activeNavigation, setActiveNavigation] = React.useState({
    activePage: "",
    activeSubPage: "",
    activeModule: activeModuleCookie,
  });

  function handleSidebarToggle(isOpen) {
    setOpen(isOpen);
    const expirationDate = new Date(Date.now() + 86400000); //1 year
    setCookie("sidebarIsOpen", isOpen, expirationDate);
  }

  const fakeDrawerClose = () => {};

  return (
    <ActiveNavigationContext.Provider
      value={{ activeNavigation, setActiveNavigation }}
    >
      <Box sx={{ display: "flex" }}>
        <Navbar setOpen={handleSidebarToggle} open={open} activeNavigation={activeNavigation} setActiveNavigation={setActiveNavigation}/>
        {window.innerWidth < 1100 ? (
          <TemporaryDrawer open={open} setOpen={setOpen} module={activeNavigation.activeModule}/>
        ) : (
          <Drawer variant="permanent" open={open}>
            <DrawerHeader sx={{ marginTop: "10px", marginBottom:"10px"}}>
              <img className="logo" src={FeedcubeIcon} alt="Usergroup" />
              {open ? (
                <>
                  <IconButton onClick={() => handleSidebarToggle(false)}>
                    <ChevronLeftIcon />
                  </IconButton>
                </>
              ) : (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => handleSidebarToggle(true)}
                  sx={{
                    backgroundColor: "white",
                    color: "rgb(99, 115, 129)",
                    border: { xs: "none", sm: "0.5px solid lightgrey" },
                    borderRadius: "100%",
                    padding: "1px",
                    position: "fixed",
                    left: "74px",
                  }}
                >
                  {window.innerWidth < 1100 ? (
                    <MenuIcon />
                  ) : (
                    <ChevronRightIcon />
                  )}
                </IconButton>
              )}
            </DrawerHeader>
            {open ? (
              <SidebarMenu setOpen={fakeDrawerClose} open={open} module={activeNavigation.activeModule}/>
            ) : (
              <SidebarMenuMini setOpen={fakeDrawerClose} open={open} module={activeNavigation.activeModule}/>
            )}
          </Drawer>
        )}
        <Box
          style={{ width: drawerWidth }}
          component="main"
          sx={{ flexGrow: 2, p: 2 }}
        >
          <DrawerHeader />
          {props.children}
        </Box>
      </Box>
    </ActiveNavigationContext.Provider>
  );
});
export default CommonSidebar;
