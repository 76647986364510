// Comments.jsx
import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import Stack from "@mui/material/Stack";
import { FaComments } from "react-icons/fa6";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AIReport from "../Evaluation/AIReport";
import { formatDate } from "../Dashboard/dashboardEvaluationFunctions";
import ContentPasteTwoToneIcon from "@mui/icons-material/ContentPasteTwoTone";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import UserAvatar from "../../Users/UserAvatar";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";

export function Comments({ feedbackData, width="100%" }) {
  const [report, setReport] = useState("");
  const [reportVisibility, setReportVisibility] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();
  let allComments = feedbackData.filter((comment) => comment.Typ === "Text");

  const searchedComments = allComments.filter((formData) => {
    const answerText = formData.Value.slice(1, -1).toLowerCase();
    return answerText.includes(searchTerm.toLowerCase());
  });

  return (
    <Fade in={true}>
      <Paper
        className="paper"
        style={{
          position: "relative",
          width: width,
          display: "flex",
          flexDirection: "column",
          maxHeight: "550px",
          overflowY: "auto",
          padding: "1rem",
        }}
      >
        {/* Header + Search + Navigation buttons */}
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <header>Kommentare</header>
          <Stack direction="row" spacing={2}>
            <Tooltip title="KI-Report">
              <IconButton size="small" onClick={() => setReportVisibility(true)}>
                <AutoAwesomeIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Kommentare">
              <IconButton size="small" onClick={() => setReportVisibility(false)}>
                <FaComments />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>

        {/* Search input field */}
        {!reportVisibility && (
          <Stack direction="row" justifyContent="flex-start" sx={{ mb: 2 }}>
            <TextField
              style={{ width: "450px" }}
              variant="standard"
              label="Kommentare durchsuchen"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton sx={{ mr: 0 /* optional spacing */ }}>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </Stack>
        )}

        {reportVisibility ? (
          <AIReport
            report={report}
            setReport={setReport}
            comments={allComments
              .map(
                (formData) =>
                  "Trainer: " +
                  formData.TrainerName +
                  ",Kurs: " +
                  formData.CourseName +
                  ",Datum: " +
                  formatDate(formData.Date) +
                  ",Frage: " +
                  formData.Fragenbeschreibung +
                  ",Kommentar: " +
                  formData.Value.slice(1, -1)
              )
              .join(" ")}
          />
        ) : (
          <Stack direction="column" spacing={2}>
            {/* Use the *filtered* array here */}
            {searchedComments.length > 0 ? (
              searchedComments.map((formData, index) => (
                <Card
                  key={index}
                  style={{ backgroundColor: "rgb(244, 250, 251)" }}
                >
                  <CardContent style={{ position: "relative" }}>
                    <IconButton
                      onClick={() => {
                        navigate("/evaluation/surveys/" + formData.id);
                      }}
                      edge="end"
                      aria-label="fragebogenansicht"
                      style={{ position: "absolute", top: "7px", right: "17px" }}
                    >
                      <ContentPasteTwoToneIcon
                        fontSize="medium"
                        color="primary"
                      />
                    </IconButton>
                    <Stack direction={"row"} alignItems={"center"}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Trainer: {formData.TrainerName}
                      </Typography>
                      <UserAvatar
                        name={formData.TrainerName}
                        src={formData.Avatarfile}
                        marginLeft={"10px"}
                        marginRight={"10px"}
                        width={"25px"}
                        height={"25px"}
                      />
                    </Stack>
                    <Typography variant="body2" color="text.secondary">
                      {formData.CourseName}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      style={{ marginBottom: "100px" }}
                    >
                      {formatDate(formData.Date)}
                    </Typography>

                    <div style={{ margin: "10px 0px 0px" }}>
                      <Typography variant="subtitle2">
                        Frage: {formData.Fragenbeschreibung}
                      </Typography>
                      <Typography variant="body1" sx={{ mt: 1 }}>
                        Antwort: {formData.Value.slice(1, -1)}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              ))
            ) : (
              <Typography variant="body2" color="text.secondary">
                Keine Kommentare gefunden.
              </Typography>
            )}
          </Stack>
        )}
      </Paper>
    </Fade>
  );
}
