import ReactApexChart from "react-apexcharts";
import { TimeLineDiagram } from "../../../Diagrams/TimeLineDiagram";

export default function BewertungTimelineDiagram(props) {
  const average = props.average ? 25 * props.average - 25 : 190; //average color is calculated as gradient between red and green
  const averageColor = "hsl(" + average + ", 90%, 45%)";

  const evaluationTimelineDiagram = JSON.parse(JSON.stringify(TimeLineDiagram));
    evaluationTimelineDiagram.options.yaxis = [
      {
        min: 1,
        max: props.max,
        title: {
          text: "Bewertung",
        },
        forceNiceScale: true,
        labels: {
          formatter: function (value) {
            return value.toFixed(1);
          },
        },
      },
    ];
  

    evaluationTimelineDiagram.options.fill = {
      type: "gradient",
      colors: [averageColor],
      gradient: {
        shadeIntensity: 0.2,
        type: "vertical",
        inverseColors: false,
        opacityFrom: 0.6,
        opacityTo: 0.2,
        stops: [30, 100],
      },
    };


  evaluationTimelineDiagram.options.responsive = [
    {
      breakpoint: 767,
      options: {
        chart: {
          height: 350,
          zoom: {
            enabled: false,
          },
        },
      },
    },
  ];

  evaluationTimelineDiagram.series = [];
    evaluationTimelineDiagram.series = [
      {
        name: "Durchschnittliche Bewertung",
        data: props.seriesForTimelineChart,
        color: averageColor,
      },
    ];
  
  evaluationTimelineDiagram.options.title.text = "Trend";

  return (
    <ReactApexChart
      options={evaluationTimelineDiagram.options}
      type={props.type}
      series={evaluationTimelineDiagram.series}
      height={380}
    />
  );
}
