// src/Common/useQueryParams.js
import { useLocation, useNavigate } from "react-router-dom";

/**
 * Custom Hook zum Arbeiten mit URL-Query-Parametern.
 *
 * @returns {Object} Ein Objekt mit Funktionen zum Setzen, Entfernen, Abrufen und Setzen mehrerer Query-Parametern.
 */
const useQueryParams = () => {
  const location = useLocation();
  const navigate = useNavigate();

  /**
   * Setzt oder aktualisiert einen Query-Parameter.
   *
   * @param {string} param - Der Name des Query-Parameters.
   * @param {string|number} value - Der Wert des Query-Parameters.
   * @param {boolean} [replace=true] - Wenn true, ersetzt die aktuelle Historieneintragung. Andernfalls wird eine neue hinzugefügt.
   */
  const updateQueryParams = (param, value, replace = true) => {
    const params = new URLSearchParams(location.search);
    if (value) {
      params.set(param, value);
    } else {
      params.delete(param);
    }
    const newSearch = params.toString();
    navigate(`${location.pathname}?${newSearch}${location.hash}`, { replace });
  };

  const updateQueryObject = (paramsToUpdate, replace = true) => {
    const params = new URLSearchParams(location.search);
  
    // Iterate over the object of parameters
    Object.entries(paramsToUpdate).forEach(([param, value]) => {
      if (value !== undefined && value !== null) {
        params.set(param, value); // Add or update the parameter
      } else {
        params.delete(param); // Remove the parameter if value is null or undefined
      }
    });
  
    const newSearch = params.toString();
    navigate(`${location.pathname}?${newSearch}${location.hash}`, { replace });
  };
  

  /**
   * Entfernt einen Query-Parameter.
   *
   * @param {string} param - Der Name des zu entfernenden Query-Parameters.
   * @param {boolean} [replace=true] - Wenn true, ersetzt die aktuelle Historieneintragung. Andernfalls wird eine neue hinzugefügt.
   */
  const removeQueryParam = (param, replace = true) => {
    updateQueryParams(param, null, replace);
  };

  /**
   * Ruft den Wert eines bestimmten Query-Parameters ab.
   *
   * @param {string} param - Der Name des Query-Parameters.
   * @returns {string|null} Der Wert des Query-Parameters oder null, wenn nicht vorhanden.
   */
  const getQueryParam = (param) => {
    const params = new URLSearchParams(location.search);
    return params.get(param);
  };

  return {
    updateQueryParams,
    updateQueryObject,
    removeQueryParam,
    getQueryParam,
  };
};

export default useQueryParams;
